body {
  margin: 0;
  padding: 0;

  /* font-family: -apple-system, BlinkMacSystemFont, 'Noto Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */

  font-family:'Noto Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* is scroll refresh */
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;

  font-family: "NotoSans-regular";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'NotoSans-bold';
  src: local('NotoSans'), url(../src/assets/font/NotoSans-Bold.ttf);
}

@font-face {
  font-family: 'NotoSans-regular';
  src: local('NotoSans'), url(../src/assets/font/NotoSans-Regular.ttf);
}