* {
  padding: 0;
  margin: 0;
}

html {
  width: 100%;
  height: 100%;
  background-color: #202125;
}

body {
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
}

body > #root {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  display: none;
}